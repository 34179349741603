import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getReq } from "../Api/api"; 
import CustomAccordion from "./Accordion";
import "../CSS/selfAssessment.css";
import { Button } from "reactstrap";
import props from "./CommunicationHistory";
import { getList } from '../Api/commonApi';


function MyApprisialHistoryList() {
  let url = process.env.REACT_APP_URL;
  const { id } = useParams(); 
//   const { questionsArray } = props;
const [questionsArray, setQuestionsArray] = useState([]);

  const [selectedAppraisalDetails, setSelectedAppraisalDetails] = useState(null);
      
  const navigate = useNavigate();

  useEffect(() => {
    
      getList(`${url}api/sa/get-all-rows`)
        .then(response => {
          setSelectedAppraisalDetails(response?.data);
        })
        .catch(error => {
          console.error(error);
        });
    
  }, [id, url]); 

  const handleBackClick = () => {
    navigate("/appraisalhistory");
  };

  return (
    <div>
      <Button
        outline
        color="default"
        className="ms-auto"
        onClick={handleBackClick}
      >
        <i className="bi bi-arrow-left"></i> 
        Back
      </Button>   
      <div className="col-md-12 appraisal_table">
								<table className="table self_assessment_table">
									<thead>
										<tr>
											<th className="table_heading">S No.</th>
											<th className="table_heading" style={{ width: "20%" }}>
												Question
											</th>
											<th className="table_heading">Employee Comments</th>
											<th className="table_heading">Rating</th>
											<th className="table_heading">Manager Comments</th>
											<th className="table_heading">Rating</th>
											<th className="table_heading">Additional Comments</th>
											<th className="table_heading">Weightage</th>
											<th className="table_heading">Attach Document</th>
										</tr>
									</thead>
									<tbody className="table_body">
										{questionsArray?.map((questionItem, index) => (
											<tr key={questionItem.questionId}>
												<td>{index + 1}</td>
												<td className="text_td">{questionItem.question}</td>
												<td className="text_td">
													{questionItem.employeeComments}
												</td>
												<td className="text_center_td">
													{questionItem.employeeRating}
												</td>
												<td className="text_td">
													{questionItem.managerComments}
												</td>
												<td className="text_center_td">
													{questionItem.managerRating}
												</td>
												<td className="text_td">
													{questionItem.additionalComments}
												</td>
												<td className="text_center_td">
													{questionItem.weightage}
												</td>
												<td className="text_td">
													{questionItem.filePath && (
														<a
															href={`${url}api/sa/download/${questionItem.filePath}`}
															target="_blank"
															rel="noopener noreferrer"
														>
															{questionItem.filePath}
														</a>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
								
	
							</div>
    </div>    
  );
}

export default MyApprisialHistoryList;
